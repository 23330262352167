<script lang="ts">
    import { toast } from '@zerodevx/svelte-toast';
    import { twMerge } from 'tailwind-merge';

    export let message = '';
    export let color: 'gray' | 'green' | 'red' = 'gray';
    export let toastId: number | undefined = undefined;

    const colors = {
        gray: 'text-gray-900 border-gray-300',
        green: 'text-green-500 border-green-300',
        red: 'text-red-500 border-red-300',
    };
</script>

<div class={twMerge('flex rounded border bg-white p-4', colors[color])}>
    <div class="flex-1 text-sm font-normal">{@html message}</div>
    <button class="ml-3 flex items-start justify-start" on:click={() => toastId !== null && toastId !== undefined && toast.pop(toastId)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M4.5 4.5L13.5 13.5M4.5 13.5L13.5 4.5L4.5 13.5Z" stroke="#D1D5DB" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </button>
</div>
