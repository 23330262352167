import type { AxiosRequestConfig } from 'axios';
export function toAxiosOptions(_options: any): AxiosRequestConfig {
    const { mediaType, query, path, body, url, formData, ...rest } = _options;
    const options: AxiosRequestConfig & { url: string } = {
        url,
        ...rest,
    };
    if (mediaType) {
        options.headers = {
            ...options.headers,
            'Content-Type': _options.mediaType,
        };
    }
    if (path) {
        Object.entries(path).forEach(([key, value]: any[]) => {
            options.url = options.url.replace(`{${key}}`, value);
        });
    }
    if (query) {
        options.params = query;
    }
    if (formData) {
        options.data = formData;
    } else if (body) {
        options.data = body;
    }
    return options;
}
