import { writable } from 'svelte/store';

interface ThemeAssets {
    faviconHref: string;
    logoHref: string;
}

interface ThemeConfig extends ThemeAssets {
    [key: string]: any;
}

const defaultTheme: ThemeConfig = {
    faviconHref: '/favicon.ico',
    logoHref: '/src/lib/assets/logo/allibee.svg',
    fontLinks: [],
};

export const themeStore = writable<ThemeConfig>(defaultTheme);

const getThemeAssetHref = async (assetType: 'favicon' | 'logo', defaultPath: string, config: any): Promise<string> => {
    const assetName = config?.[assetType];

    if (!assetName) return defaultPath;

    try {
        const assets = assetType === 'favicon' ? import.meta.glob('$lib/assets/favicons/*') : import.meta.glob('$lib/assets/logo/*');

        const assetKey = Object.keys(assets).find(key => {
            const fileName = key.split('/').pop();
            return fileName === assetName;
        });

        if (assetKey) {
            const module: any = await assets[assetKey]();
            return module.default;
        }
    } catch (error) {
        console.error(`${assetType}을 찾을 수 없습니다: ${config[assetType]}`, error);
    }

    return defaultPath;
};

export const updateThemeConfig = async (themeConfigs: any) => {
    const [faviconHref, logoHref] = await Promise.all([getThemeAssetHref('favicon', defaultTheme.faviconHref, themeConfigs), getThemeAssetHref('logo', defaultTheme.logoHref, themeConfigs)]);

    themeStore.update(state => ({
        ...state,
        ...themeConfigs,
        faviconHref,
        logoHref,
    }));
};

// 초기 테마 설정 적용
updateThemeConfig(__CONTRACT_THEME_CONFIG__);
