/* eslint-disable */
// https://dev2.bhsn.it/api/alex/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@allibee/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'alex';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * id 범위의 계약서를 Elasticsearch에 재색인하기 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function reindexByIdRange(
    {
        idStart,
        idEnd,
    }: {
        idStart: number;
        idEnd: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/reindex/id-range/v1',
                query: {
                    idStart: idStart,
                    idEnd: idEnd,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 전체 계약서를 Elasticsearch에 재색인하기 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function reindexAll(_?: any, requestConfig?: RequestConfig): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/reindex/all/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 수정하기 V2
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractV2(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractUpdateFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/v2',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 제목 수정
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function updateContractTitle(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractTitleUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/title/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 등록하기 V5
 * @returns AlexResponseContractCreateResponseDto OK
 * @throws ApiError
 */
export function createContractV5(
    {
        requestBody,
    }: {
        requestBody: AlexContractCreateFormV5;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseContractCreateResponseDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/v5',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 체결본 등록하기 V4
 * @returns AlexResponseContractCreateResponseDto OK
 * @throws ApiError
 */
export function createContractV4(
    {
        requestBody,
    }: {
        requestBody: AlexContractCreateFormV4;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseContractCreateResponseDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/v4',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 무한 스크롤 검색 V1
 * @returns AlexResponseListContractScrollSearchResultV1 OK
 * @throws ApiError
 */
export function scrollSearchContractsV1(
    {
        requestBody,
        fetchSize,
        sortValue,
        tieBreaker,
    }: {
        requestBody: AlexContractScrollSearchConditionV1;
        /**
         * 가져올 데이터 수
         */
        fetchSize?: number;
        /**
         * 마지막 데이터의 sortValue - 조회 조건이 있으면 score 값, 없으면 createdAt 값
         */
        sortValue?: string;
        /**
         * 마지막 데이터의 tieBreaker
         */
        tieBreaker?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseListContractScrollSearchResultV1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/scroll-search/v1',
                query: {
                    fetchSize: fetchSize,
                    sortValue: sortValue,
                    tieBreaker: tieBreaker,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 페이징 검색 V2 - FE에서 스크롤 방식으로 전환하면 사용하지 않을 예정
 * @returns AlexResponsePageContractPageSearchResultV2 OK
 * @throws ApiError
 */
export function pageSearchContractsV2(
    {
        requestBody,
        page,
        size = 10,
        sort,
    }: {
        requestBody: AlexContractPageSearchConditionV1;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponsePageContractPageSearchResultV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/page-search/v2',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 - 엑셀 다운로드 V1
 * @returns any OK
 * @throws ApiError
 */
export function excelContractsV1(
    {
        requestBody,
    }: {
        requestBody: AlexContractScrollSearchConditionV1;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/excel/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 분석하기 V2
 * @returns AlexResponseContractAnalysisDtoV2 OK
 * @throws ApiError
 */
export function analyzeContractV2(
    {
        requestBody,
    }: {
        requestBody: AlexContractAnalyzerForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseContractAnalysisDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/analyze/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 간단 수정하기 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function patchContractSimple(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexContractSimpleUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PATCH',
                url: '/contracts/{contractId}/simple/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 코드 목록
 * @returns any OK
 * @throws ApiError
 */
export function getRepositoryCodes(_?: any, requestConfig?: RequestConfig): AxiosPromise<Record<string, Array<Record<string, any>>>> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/repository-codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 상세 조회 V4
 * @returns AlexResponseContractDtoV4 OK
 * @throws ApiError
 */
export function getContractV4(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseContractDtoV4> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/v4',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 상세 조회 V3
 * @returns AlexResponseContractDtoV3 OK
 * @throws ApiError
 */
export function getContractV3(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseContractDtoV3> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/v3',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 PDF 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadContract(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/download/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 상세 조회 by conhub contract ID V2
 * @returns AlexResponseContractDtoV4 OK
 * @throws ApiError
 */
export function getContractByConhubContractIdV2(
    {
        conhubContractId,
    }: {
        /**
         * Conhub 계약 ID
         */
        conhubContractId?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseContractDtoV4> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/v2',
                query: {
                    conhubContractId: conhubContractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 상세 조회 by conhub contract ID V1
 * @returns AlexResponseContractDtoV3 OK
 * @throws ApiError
 */
export function getContractByConhubContractId(
    {
        conhubContractId,
    }: {
        /**
         * Conhub 계약 ID
         */
        conhubContractId?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponseContractDtoV3> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/v1',
                query: {
                    conhubContractId: conhubContractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 PDF 다운로드 by conhub contract ID
 * @returns any OK
 * @throws ApiError
 */
export function downloadContractByConhubContractId(
    {
        conhubContractId,
    }: {
        /**
         * Conhub 계약 ID
         */
        conhubContractId?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/download/by-conhub-contract-id/v1',
                query: {
                    conhubContractId: conhubContractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 삭제
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function deleteContract(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 라벨 조항 삭제
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function deleteLabelClauseExtraction(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AlexLabelClauseExtractionDeleteForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/labelClauseExtraction/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 Bulk 등록 V1
 * @returns AlexResponse OK
 * @throws ApiError
 */
export function createBulkContractsV1(
    {
        requestBody,
    }: {
        requestBody: AlexContractBulkCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/contracts/bulk/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 통합 검색 (페이지)
 * @returns AlexResponsePageUnifiedSearchPageResult OK
 * @throws ApiError
 */
export function pageSearchUnified(
    {
        requestBody,
        page,
        size = 10,
        sort,
    }: {
        requestBody: AlexUnifiedSearchCondition;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AlexResponsePageUnifiedSearchPageResult> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/unified-search/page/v1',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 통합 검색 코드 목록
 * @returns any OK
 * @throws ApiError
 */
export function getUnifiedSearchCodes(_?: any, requestConfig?: RequestConfig): AxiosPromise<Record<string, Array<Record<string, any>>>> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/unified-search-codes/v1',
            }),
            requestConfig,
        ),
    );
}
